@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_content_image_switch {
    padding: 52px 0;

    @include breakpoint(large) {
        padding: 84px 0;
    }

    video {
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .heading-h2 {
        margin-bottom: 20px;

        @include breakpoint(large) {
            margin-bottom: 32px;
        }
    }

    .image {
        order: 1;
        position: relative;

        @include breakpoint(medium down) {
            margin-bottom: 36px;
        }

        img {
            width: 100%;

            @include breakpoint(large) {
                width: auto;
            }
        }

        &.play {
            video {
                z-index: 1;
            }

            .pause-button {
                display: flex;
                opacity: 1;
                pointer-events: all;
                transition-delay: 150ms;
                z-index: 2;
            }
        }
    }

    .content {
        order: 2;
    }

    .button {
        margin-top: 24px;

        @include breakpoint(medium down) {
            width: 100%;
            text-align: center;
        }
    }

    &.reversed {
        .image {
            order: 1;

            @include breakpoint(large) {
                order: 2;
            }
        }

        .content {
            order: 2;

            @include breakpoint(large) {
                order: 1;
            }
        }
    }

    .font-text-s {
        text-align: center;
        margin-top: 12px;

        @include breakpoint(large){
            width: 40%;
        }

        @include breakpoint(xlarge){
            width: 30%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:50";